<template>
  <v-layout class="greytext ggfont">
    <v-container class="mt-0" style="max-width:1000px;">
      <v-row class="mb-8">
        <v-col cols="12" md="8">
          <h2 v-if="showError" class="red--text">
            Fehler - leider gab es bei der Übertragung einen Fehler. Am besten
            schicken Sie uns eine kurze E-Mail: info@eisbaumtabelle.de
          </h2>
          <h2 v-if="!showSuccess">
            EisbaumTabelle kostenlos nachbestellen
          </h2>
          <h2 v-if="showSuccess">
            Ihre kostenlose Nachbestellung der EisbaumTabellen wird so schnell
            wie möglich versendet
          </h2>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
  export default {
    name: "sofort-bestellung",
    components: {},

    data: function() {
      return {
        showSuccess: false,
        showError: false,
        newBestellung: {
          bestell_type: 1,
          bestell_produkt_text: "",
        },
        select: {
          token: this.$route.params.token,
          action: "startlogintoken",
        },
      };
    },
    created() {
      this.setTitle();
      // this.$store.commit("start_loading_full");
      if (this.select.token != "") {
        this.checkToken();
      } else {
        this.showSuccess = false;
        this.showError = true;
      }
    },

    methods: {
      checkToken() {
        this.$store
          .dispatch("apiUserPost", this.select)
          .then((resp) => {
            if (resp.data.user) {
              this.$router.push("/account");
              // this.newBestellung.user_id = resp.data.user.user_id;
              // this.newBestellung.action = "insertbestellungtoken";
              // setTimeout(this.insertBestellung(), 1000);
            } else {
              this.showSuccess = false;
              this.showError = true;
              this.$store.commit("stop_loading_full");
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      insertBestellung() {
        this.$store
          .dispatch("apiUserPost", this.newBestellung)
          .then((resp) => {
            if (resp.data) {
              this.showSuccess = true;
              this.showError = false;
              this.$store.commit("stop_loading_full");
            }
          })
          .catch((err) => {
            this.$log.error(err);
            this.$store.commit("stop_loading_full");
          });
      },
      setTitle() {
        this.$log.debug("title");
        document.title = "EisbaumTabelle kostenlos nachbestellen";
        let metadesc = "EisbaumTabelle kostenlos nachbestellen";
        //this.saveReferrer();
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metadesc);
        document.dispatchEvent(new Event("x-app-rendered"));
      },
    },
  };
</script>
